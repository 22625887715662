import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["items", "burger"]

  toggle() {
    this.burgerTarget.classList.toggle("is-active")
    this.itemsTarget.classList.toggle("is-active")

    const isExpanded = this.burgerTarget.classList.contains("is-active")
    this.burgerTarget.setAttribute("aria-expanded", isExpanded)
  }
}
